import React from "react"
import { PageProps } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage: React.FC<PageProps> = ({ location }) => (
  <Layout location={location}>
    <SEO title="404: Not found" />
  </Layout>
)

export default NotFoundPage
